import React from 'react'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'

export default function AdvantagesVsFossil() {
    return (
        <Layout>
            {/* <SEO title={appState.i18n.navs.advantagesVsFossil} /> */}
        </Layout>

    )
}
